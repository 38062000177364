import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store";
import AuthService from "@/serives/AuthService";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    meta: { shouldLoggedOut: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Login.vue"),
  },
  {
    path: "",
    name: "ProductList",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/ProductList.vue"),
  },
  {
    path: "/product/:id",
    name: "Product",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Product.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (Store.state.isLoggedIn === undefined) AuthService.initLoggedIn();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }
  if (to.matched.some((record) => record.meta.shouldLoggedOut)) {
    if ((to.name && to.name === "Login") || to.fullPath === "/login") {
      if (Store.state.isLoggedIn) {
        next({
          path: "/",
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
