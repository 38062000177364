import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCompositionAPI from "@vue/composition-api";
import { Table, Input, Button, Menu, Dropdown } from "ant-design-vue";
import VueShortkey from "vue-shortkey";

import "ant-design-vue/dist/antd.css";

Vue.use(VueCompositionAPI);
Vue.use(VueShortkey);

Vue.component(Table.name, Table);
Vue.component(Input.name, Input);
Vue.component(Button.name, Button);
Vue.component(Input.TextArea.name, Input.TextArea);
Vue.component(Input.Search.name, Input.Search);
Vue.component(Menu.name, Menu);
Vue.component(Menu.Item.name, Menu.Item);
Vue.component(Dropdown.Button.name, Dropdown.Button);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
