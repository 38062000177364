import LocalStorageService from "./LocalStorageService";
import StorageKey from "@/types/StorageKey";
import TokenSet from "@/types/TokenSet";
import Store from "@/store";

class AuthService {
  private readonly _localStorageService: LocalStorageService;

  constructor(localStorageService: LocalStorageService) {
    this._localStorageService = localStorageService;
  }

  public initLoggedIn(): void {
    let tokenset: TokenSet | undefined = undefined;
    const tokensetString = this._localStorageService.get(StorageKey.TOKENSET);
    if (tokensetString && tokensetString.length > 0)
      tokenset = JSON.parse(tokensetString);

    Store.dispatch(
      "setLoggedIn",
      tokenset && tokenset.access_token ? true : false
    );
  }

  public logout() {
    Store.dispatch("setLoggedIn", false);
    this._localStorageService.remove(StorageKey.TOKENSET);
    this._localStorageService.remove(StorageKey.ACCOUNT);
    this._localStorageService.remove(StorageKey.FEATURES);
  }
}

export default new AuthService(new LocalStorageService());
