






import { defineComponent } from "@vue/composition-api";
import AuthService from "@/serives/AuthService";
import { mapState } from "vuex";

export default defineComponent({
  name: "Header",
  computed: {
    ...mapState(["isLoggedIn"]),
  },
  setup(props, context) {
    const router = context.root.$router;

    const handleLogout = async (): Promise<void> => {
      AuthService.logout();
      router.push("/login");
    };

    return {
      handleLogout,
    };
  },
});
