import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: undefined,
  },
  mutations: {
    SET_IS_LOGGED_IN(state: any, isLoggedIn: boolean): void {
      state.isLoggedIn = isLoggedIn;
    },
  },
  actions: {
    setLoggedIn({ commit }: any, isLoggedIn: boolean): void {
      commit("SET_IS_LOGGED_IN", isLoggedIn);
    },
  },
  modules: {},
});
