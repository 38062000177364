







import { defineComponent } from "@vue/composition-api";
import Header from "@/components/Header.vue";

export default defineComponent({
  components: {
    Header,
  },
});
